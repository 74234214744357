<template>
  <span class="icon-button" :class="{disabled: disabled}" :disabled="disabled">
    <unicon :width="size" :height="size" :class="iconClass" :name="icon"/>
  </span>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'plus'
    },
    iconClass: {
      type: String,
      default: 'color-primary'
    },
    size: {
      type: Number,
      default: 18
    }
  },
  methods: {
    onClickHandler() {
      if(!this.disabled) {
        this.$emit('onClick')
      }
    }
  }
}
</script>

<style scoped>

</style>