import BaseModel from './BaseModel'
import Client from "@/models/Client";
export default class Event extends BaseModel {
    static entity = 'events'

    static fields () {
        return {
            id: this.attr(null),
            type: this.attr('service'),
            client_id: this.attr(null),
            client: this.attr(null),
            date_start: this.attr(null),
            date_end: this.attr(null),
            finished_at: this.attr(null),
            status: this.attr(null),
            services: this.attr([]),


            comment: this.attr(''),
            created_at: this.attr(''),
            updated_at: this.attr(''),
            tags: this.attr([]),
        }
    }
}
