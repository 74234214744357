<template>
  <span>
      {{ displayedAmount }}
  </span>
</template>

<script>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import useDateHelper from "@/use/useDateHelper";

export default {
  setup(props, {emit}) {
    const timestamp = ref(0)
    const startTimestamp = ref(0)
    const currentAmount = ref(0)
    const currentStartAmount = ref(0)
    const currentDuration = ref(0)
    const paused = ref(false)
    const remaining = ref(0)
    const animationFrame = ref(0)
    const start = () => {
      cancelAnimation()
      currentStartAmount.value = currentAmount.value
      startTimestamp.value = null
      currentDuration.value = props.duration
      paused.value = false
      animationFrame.value = window.requestAnimationFrame(counting)
    }
    const pause = () => {
      if (paused.value) return;
      cancelAnimation();
      paused.value = true;
    }
    const resume = () => {
      if (!paused.value) return;
      startTimestamp.value = null;
      currentDuration.value = +remaining.value;
      currentStartAmount.value = +currentAmount.value;
      animationFrame.value = window.requestAnimationFrame(counting);
      paused.value = false;
    }
    const reset = () => {
      paused.value = false;
      startTimestamp.value = null;
      cancelAnimation();
      //currentAmount.value = props.startAmount;
      if (props.autoinit) start();
      else paused.value = true;
    }

    const counting = (ts) => {
      timestamp.value = ts;
      if (!startTimestamp.value) startTimestamp.value = ts;
      let progress = ts - startTimestamp.value;
      remaining.value = currentDuration.value - progress;

      if (!isCountingUp.value) {
        currentAmount.value = currentStartAmount.value - ((currentStartAmount.value - props.value) * (progress / currentDuration.value));
        currentAmount.value = currentAmount.value < props.value ? props.value : currentAmount.value;
      } else {
        currentAmount.value = currentStartAmount.value + (props.value - currentStartAmount.value) * (progress / currentDuration.value);
        currentAmount.value = currentAmount.value > props.value ? props.value : currentAmount.value;
      }

      if (progress < currentDuration.value) animationFrame.value = window.requestAnimationFrame(counting);
      else emit('finished');
    }

    const cancelAnimation = () => {
      if (animationFrame.value) window.cancelAnimationFrame(animationFrame.value);
    }

    const isCountingUp = computed(() => props.value > currentAmount.value)

    const displayedAmount = computed(() => `${props.prefix}${formattedAmount.value}${props.suffix}`)
    const formattedAmount = computed(() => {
      const regex = /(\d+)(\d{3})/;

      let numberString = currentAmount.value.toFixed(props.decimals);
      numberString += '';
      let numberArray = numberString.split('.');
      let numbers = numberArray[0];
      let decimals = numberArray.length > 1 ? props.decimalSeparator + numberArray[1] : '';
      let isNumber = !isNaN(parseFloat(props.separator));

      if (props.separator && !isNumber) {
        while (regex.test(numbers)) numbers = numbers.replace(regex, '$1' + props.separator + '$2');
      }
      return numbers + decimals;
    })

    watch(() => props.value, reset)
    watch(() => props.duration, reset)

    onMounted(() => {
      currentAmount.value = 0;
      currentStartAmount.value = 0;
      currentDuration.value = props.duration;
      remaining.value = props.duration;
      if (props.autoinit) start();
      else paused.value = true;
      emit('mounted');
    })

    onUnmounted(cancelAnimation)

    return {
      displayedAmount
    }
  },
  name: "NumberSpinner",
  props: {
    startAmount: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0,
      required: true
    },
    duration: {
      type: Number,
      default: 1000,
      validator(duration) {
        return duration >= 1;
      }
    },
    autoinit: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    separator: {
      type: String,
      default: ' '
    },
    decimalSeparator: {
      type: String,
      default: '.'
    },
    decimals: {
      type: Number,
      default: 0,
      validator(decimals) {
        return decimals >= 0;
      }
    }
  }
}
</script>
