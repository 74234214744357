const baseUrl = process.env.VUE_APP_BASE_URL
const apiVersion = 1
const phoneMask = '+7(###)###-####'

const data = {
    baseUrl: baseUrl,
    phoneMask: phoneMask,
    apiVersion: apiVersion,
    apiUrl: baseUrl+'/api/v'+apiVersion+'/'
};

export default (app) => {
    if(app) {
        app.config.globalProperties.$config = data
    }
    return data
}
