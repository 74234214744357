import useStringHelper from "@/use/useStringHelper";

export default function useDateHelper() {

    const prettyRange = (from, to) => {

        if(from && to) {
            if(from.isSame(to, 'day')) {
                return from.format('D MMMM YYYY')
            }
            else if(from.isSame(to, 'month')) {
                return from.format('D') + ' - ' + to.format('D MMMM YYYY')
            }
            else if(from.isSame(to, 'year')) {
                return from.format('D MMMM') + ' - ' + to.format('D MMMM YYYY')
            }
            return from.format('D MMMM YYYY') + ' - ' + to.format('D MMMM YYYY')
        }
        else if(from) {
            return from.format('D MMMM YYYY')
        }
        else if(to) {
            return to.format('D MMMM YYYY')
        }
        return 'Период не указан'
    }

    const humanMinutes = (val) => {
        const { plural } = useStringHelper()

        let mins_num = parseFloat(val, 10); // don't forget the second param
        let hours   = Math.floor(mins_num / 60);
        let minutes = Math.floor((mins_num - ((hours * 3600)) / 60));

        if(hours) {
            if(minutes) {
                if (minutes < 10) {minutes = "0"+minutes}
                return plural(hours, ['час', 'часа', 'часов'])+' '+minutes+' мин'
            }
            return plural(hours, ['час', 'часа', 'часов'])

        } else {
            return val+'мин'
        }


    }
    
    return {
        prettyRange,
        humanMinutes
    }
}
