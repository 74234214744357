<template>
    <div class="bg-white client-card" :class="{inset: inset, 'p-2': !inset}" v-if="model">
      <Avatar :src="model.avatar" :name="model.fullname"/>
      <div>
        <h4 class="mt-0 mb-1">{{ model.fullname }}</h4>
        <div class="phone">
          <unicon width="16" height="16" name="mobile-android" /> <span>{{ model.phone }}</span>
        </div>
      </div>
    </div>
</template>

<script>
import Avatar from "@/components/helpers/Avatar";
import Client from "@/models/Client";

export default {
  name: "ClientCardSimple",
  components: {
    Avatar,
  },
  props: {
    inset: {
      type: Boolean,
      default:false
    },
    model: {
      //type: Client||Object,
      default: new Client()
    }
  }
}
</script>