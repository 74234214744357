import { createApp } from 'vue'
import Vant from "@/setup/Vant";
import App from './App.vue'
import Pinia from "@/store"
import Router from './router';
import Auth from "@/services/auth";
import Icons from "@/setup/Icons";
import Utils from "@/setup/Utils";
import Emitter from '@/setup/Emitter'
import Config from "@/setup/Config";
import Sentry from "@/setup/Sentry";
import CommonComponents from "@/setup/CommonComponents";
import './registerServiceWorker'
import "@/setup/Css"

const app = createApp(App)

app
    .use(Vant)
    .use(Pinia)
    .use(Router)
    .use(Auth)
    .use(Config)
    .use(Icons)
    .use(Utils)
    .use(Emitter)
    .use(CommonComponents)

// Sentry(app, Router)

app.mount('#app');
