<template>
  <div class="d-flex align-items-center gap-1 mb-1">
    <van-field
        v-if="hasVariants"
        is-link
        readonly
        :label="option?.title"
        placeholder="Выберите опцию"
        @click="variantsPickerIsVisible = true"
    >
      <template #input v-if="currentValue">
        <span class="pr-1 strong">
          {{ currentValue.title }}
        </span>

        <span>
          <span v-if="currentValue.price" class="mark">+ {{ currentValue.price }} ₽</span>
        <span v-if="currentValue.time" class="mark">+ {{ currentValue.time }} мин.</span>
        </span>
      </template>
    </van-field>

    <div v-else>
      <van-field v-model="currentOption.value"
                 :label="option?.title"
                 placeholder="Укажите значение"
      />
    </div>

    <van-popup v-if="hasVariants" v-model:show="variantsPickerIsVisible" round position="bottom">
      <van-picker
          :title="option?.title"
          :columns="variantsForPicker"
          @cancel="variantsPickerIsVisible = false"
          @confirm="onVariantSelect"
          @click-option="onVariantSelect"
      >
        <template #option="v">
          <template v-if="!!v.model">
            <span class="px-1 strong">
              {{ v.text }}
            </span>
            <span v-if="v.model.price" class="mark">+ {{ v.model.price }} ₽</span>
            <span v-if="v.model.time" class="mark">+ {{ v.model.time }} мин.</span>
          </template>
          <template v-else>{{ v.text }}</template>
        </template>
      </van-picker>
    </van-popup>

  </div>
</template>

<script>
import {computed, ref} from "vue";

import EventService from "@/utils/EventService/EventService";

export default {
  setup(props, {emit}) {
    const variantsPickerIsVisible = ref(false)
    const variants = computed(() => props.option?.variants)
    const hasVariants = computed(() => !!variants.value?.length)
    const currentOption = computed(() => props.eventService.options?.find(o => o.id === props.option.id))
    const currentValue = computed(() => {
      if (currentOption.value) {
        if (typeof currentOption.value.value === 'object') {
          return currentOption.value.value
        }
        return currentOption.value.value
      }
      return null
    })
    const variantsForPicker = computed(() => {
      if (hasVariants.value) {
        return [
          ...[{text: 'Не указывать', value: null}],
          ...variants.value.map(variant => ({
            text: variant.title,
            value: variant.id,
            model: variant
          }))
        ]
      }
      return []
    })

    const applyValue = (value) => {
      currentOption.value.value = value
    }

    const onVariantSelect = ({selectedOptions}) => {
      if (selectedOptions[0].model) {
        applyValue({
          id: selectedOptions[0].model.id,
          title: selectedOptions[0].model.title,
          price: selectedOptions[0].model.price,
          time: selectedOptions[0].model.time
        })
      } else {
        applyValue(null)
      }

      variantsPickerIsVisible.value = false

      console.log(props.eventService)
    }

    return {
      variantsForPicker,
      onVariantSelect,
      variants,
      hasVariants,
      currentValue,
      currentOption,
      variantsPickerIsVisible
    }
  },
  name: "ServicePickerOptionsField",
  props: {
    option: {
      type: Object,
      required: true
    },
    eventService: {
      type: EventService,
      required: true
    }
  }
}
</script>

<style scoped>

</style>