import BaseModel from './BaseModel'
import Event from "@/models/Event";
export default class Client extends BaseModel {
    static entity = 'clients'

    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(''),
            surname: this.attr(''),
            fullname: this.attr(''),
            avatar: this.attr(null),
            phone: this.attr(''),
            cleared_phone: this.attr(''),
            comment: this.attr(''),
            instagram: this.attr(''),
            created_at: this.attr(''),
            updated_at: this.attr(''),
            tags: this.attr([]),
            event: this.hasMany(Event, 'client_id')
        }
    }
}
