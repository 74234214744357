<template>
  <div>
    <van-button @click="servicePickerIsVisible = true" round size="small">
      <template #icon>
        <unicon width="18" height="18" class="color-primary mr-1" name="plus-circle"/>
      </template>
      {{ services.length ? 'Добавить ещё услугу' : 'Выбрать услугу'}}
    </van-button>


    <van-action-sheet
        @select="createNewService"
        v-model:show="servicePickerIsVisible"
        :actions="servicesForActionSheet"
        cancel-text="Отмена"
        close-on-click-action
    />

    <van-popup
        class="p-2"
        position="bottom"
        v-model:show="optionsPickerIsVisible"
        round
        closeable
    >
      <h4 class="mt-0 mb-2 mr-4"><span class="ghost">Добавить услугу:</span> {{ eventServiceModel.title }}</h4>


      <div class="price-time">
        <span>
          <IconButton icon="money-bill" :size="24"/>
           <ServicePickerPrice :model="eventServiceModel"/>
        </span>

        <span><IconButton icon="clock" :size="24"/>
        <ServicePickerTime :model="eventServiceModel"/></span>
      </div>




      <p>Комментарий</p>


      <div v-if="selectedService.options?.length">
        <template v-for="option in selectedService.options">
          <ServicePickerOptionsField :option="option" :event-service="eventServiceModel"/>
        </template>
      </div>

      <van-button @click="save" type="primary" round block>Добавить услугу</van-button>
    </van-popup>
  </div>
</template>

<script>

import {useRepo} from "pinia-orm/dist";
import Service from "@/models/Service";
import {computed, ref} from "vue";
import EventService from "@/utils/EventService/EventService";
import ServicePickerOptionsField from "@/components/services/ServicePicker/ServicePickerOptionsField";
import ServicePickerPrice from "@/components/services/ServicePicker/ServicePickerPrice";
import ServicePickerTime from "@/components/services/ServicePicker/ServicePickerTime";
export default {
  setup(props, {emit}) {
    const eventServiceModel = ref(null)
    const servicePickerIsVisible = ref(false)
    const optionsPickerIsVisible = ref(false)
    const servicesInRepo = computed(() => useRepo(Service).all())
    const servicesForActionSheet = computed(() => servicesInRepo.value.map(service => ({
      name: service.title,
      service: service
    })))
    const selectedService = computed(() => {
      if(servicesInRepo.value?.length && eventServiceModel.value?.service_id) {
        return servicesInRepo.value.find(s => s.id === eventServiceModel.value.service_id)
      }
      return null
    })

    const createNewService = ({service}) => {
      eventServiceModel.value = EventService.make(service)
      optionsPickerIsVisible.value = true
      servicePickerIsVisible.value = false
    }

    const edit = (service) => {
      eventServiceModel.value = service
      optionsPickerIsVisible.value = true
      servicePickerIsVisible.value = false
    }

    const save = () => {
      emit('save', eventServiceModel.value)
      optionsPickerIsVisible.value = false
      servicePickerIsVisible.value = false
    }

    return {
      selectedService,
      eventServiceModel,
      servicePickerIsVisible,
      optionsPickerIsVisible,
      createNewService,
      servicesForActionSheet,
      save,
      edit
    }
  },
  name: "ServicePicker",
  components: {
    ServicePickerOptionsField,
    ServicePickerPrice,
    ServicePickerTime
  },
  props: {
    services: {
      type: Array,
      default: []
    }
  }
}
</script>

<style lang="scss">
.price-time {
  background-color: var(--van-gray-3);
  margin: 0 -1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  font-size: 1.2rem;
  font-weight: 600;

  &>span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

</style>