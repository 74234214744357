/**
 * Клонирует массив и отвязывает любую реактивность
 * @param array
 * @returns {any}
 */
function cloneArray(array) {
    return JSON.parse(JSON.stringify(array))
}

/**
 * Плавная прокурутка экрана до нужного ref
 * @param ref
 */
function scrollTo(ref) {
    if (ref && ref.$el) {
        window.scrollBy({
            top: ref.$el.getBoundingClientRect().top,
            behavior: 'smooth'
        })
    }
}

function scrollTop() {
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
}

/**
 * Склонение существительных в зависимости от количества
 * @param count
 * @param titles
 * @param showCount
 * @param cases
 * @returns {string|*}
 */
function plural(count, titles, showCount = true, cases = [2, 0, 1, 1, 1, 2]) {
    if (showCount) {
        return count + ' ' + titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]]
    } else {
        return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]]
    }
}

/**
 * Обрезает строку до нужной длины
 */
function strLimit(str, limit = 20, showEllipse = true) {
    if (str.length && str.length > limit) {
        let limited = str.substring(0, limit)
        if (showEllipse) limited += "..."
        return limited
    }
    return str
}

const helpers = {
    plural,
    cloneArray,
    scrollTo,
    strLimit,
    scrollTop
}

export default helpers