<template>
  <NumberSpinner
      :value="totalPrice"
      :duration="300"
      suffix=" ₽"
      autoinit/>
</template>

<script>
import EventService from "@/utils/EventService/EventService";
import {computed} from "vue";
import NumberSpinner from "@/components/helpers/NumberSpinner";
export default {
  setup(props) {
    const totalPrice = computed(() => {
      let acc = props.model.price ? props.model.price : 0
      props.model.options?.forEach(o => {
        if(o.value?.price) acc += o.value.price
      })
      return acc
    })

    return {
      totalPrice
    }
  },

  name: "ServicePickerPrice",
  props: {
    model: {
      type: EventService,
      required: true
    }
  },
  components: {
    NumberSpinner
  }
}
</script>

<style scoped>

</style>