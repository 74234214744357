import Unicon from 'vue-unicons'
import {
    uniEnvelope,
    uniKeySkeletonAlt,
    uniMobileAndroid,
    uniEllipsisV,
    uniPlus,
    uniEstate,
    uniHouseUserMonochrome,
    uniCheck,
    uniAppsMonochrome,
    uniFilter,
    uniChartPieMonochrome,
    uniPen,
    uniPlusCircle,
    uniTimesCircle,
    uniTimes,
    uniTrashAlt,
    uniWhatsapp,
    uniInstagram,
    uniTelegramAlt,
    uniPhone,
    uniRefresh,
    uniSignout,
    uniCalenderMonochrome,
    uniCommentInfoAlt,
    uniCalendarAlt,
    uniFileCheckAlt,
    uniCog,
    uniListUl,
    uniSlidersVAlt, uniQuestionCircle, uniWatch, uniClock, uniMoneyStack, uniMoneyBill
} from 'vue-unicons/dist/icons'

Unicon.add([
    uniEnvelope,
    uniKeySkeletonAlt,
    uniMobileAndroid,
    uniEllipsisV,
    uniPlus,
    uniCheck,
    uniEstate,
    uniHouseUserMonochrome,
    uniAppsMonochrome,
    uniFilter,
    uniChartPieMonochrome,
    uniPen,
    uniPlusCircle,
    uniTimesCircle,
    uniTimes,
    uniTrashAlt,
    uniWhatsapp,
    uniInstagram,
    uniTelegramAlt,
    uniPhone,
    uniRefresh,
    uniListUl,
    uniSignout,
    uniCalenderMonochrome,
    uniCommentInfoAlt,
    uniCalendarAlt,
    uniFileCheckAlt,
    uniCog,
    uniSlidersVAlt,
    uniQuestionCircle,
    uniClock,
    uniMoneyBill
])

export default (app) => {
    app.use(Unicon)
}