import EventServiceOption from "@/utils/EventService/EventServiceOption";
import { v4 as uuidv4 } from 'uuid';

export default class EventService {
    id
    service_id = null
    title = null
    options = []
    comment = null
    price = null
    time = null

    constructor(service = null) {
        this.id = uuidv4()

        if(service) {
            this.service_id = service.id
            this.price = service.price
            this.time = service.time
            this.title = service.title

            if(service.options) {
                this.options = service.options.map(s => {
                    return EventServiceOption.make(s)
                })
            }
        }
    }

    static make(service) {
        return  new EventService(service)
    }
}