import BaseModel from './BaseModel'

export default class Service extends BaseModel {
    static entity = 'services'

    static fields () {
        return {
            id: this.attr(null),
            title: this.attr(''),
            active: this.boolean(true),
            time: this.number(60),
            price: this.attr(null),
            sort: this.number(999),
            options: this.attr([])
        }
    }
}
