<template>
  <van-config-provider>
    <div class="van-safe-area-top"></div>
    <router-view></router-view>

    <van-popup
        closeable
        v-model:show="sidebarVisible"
        position="left"
        :style="{ width: '80%', height: '100%' }"
    >
      <div class="h-100 d-flex flex-column">
        <div class="p-2">
          <HeaderLogo/>
        </div>

        <template v-if="auth.user">
          <van-cell-group @click="closeSideBar">

            <van-cell center>
              <template #title>
                <div class="d-flex align-items-center gap-1">
                  <Avatar :name="auth.user().name + ' '+auth.user().surname"/>
                  <h4 class="m-0">
                    {{ auth.user().name }}
                    {{ auth.user().surname }}
                  </h4>
                </div>
              </template>
            </van-cell>

            <van-cell is-link :to="{name: 'settings'}" title="Настройки" center>
              <template #icon>
                <unicon class="mr-2" name="cog"/>
              </template>
            </van-cell>

            <van-cell @click="auth.logout()" title="Выход" center>
              <template #icon>
                <unicon class="mr-2 color-error" name="signout"/>
              </template>
            </van-cell>
          </van-cell-group>


        </template>

        <div class="flex-grow-1"></div>
        <div class="p-2 small ghost">
          Сделано с <span class="color-error">❤</span> для Анны Марковой
        </div>
      </div>
    </van-popup>

    <EventFormPopup/>

    <div class="van-safe-area-bottom"></div>
  </van-config-provider>

</template>

<script>
import {ConfigProvider} from 'vant';
import Service from "@/models/Service";
import Avatar from "@/components/helpers/Avatar";
import EventFormPopup from "@/components/events/EventFormPopup";
import useAuth from "@/use/useAuth";
import {onMounted, ref} from "vue";
import useEmitter from "@/use/useEmitter";
import useApi from "@/use/useApi";

export default {
  setup() {
    const api = useApi()
    const auth = useAuth()
    const emitter = useEmitter()
    const sidebarVisible = ref(false)
    const closeSideBar = () => sidebarVisible.value = false

    onMounted(() => {
      emitter.on('toggle-sidebar', () => {
        sidebarVisible.value = !sidebarVisible.value
      })
      auth.loadUser(api)
      Service.api().getAll()
    })

    return {
      auth,
      sidebarVisible,
      closeSideBar
    }
  },
  name: "LayoutDefault",
  components: {
    [ConfigProvider.name]: ConfigProvider,
    Avatar,
    EventFormPopup
  }
}
</script>

<style scoped>

</style>