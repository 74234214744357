<template>
  <router-view></router-view>

  <van-tabbar route>
    <van-tabbar-item :to="{name: 'events'}">
      <span>События</span>
      <template #icon="props">
        <unicon name="calender" icon-style="monochrome" />
      </template>
    </van-tabbar-item>

    <van-tabbar-item :to="{name: 'clients'}">
      <span>Клиенты</span>
      <template #icon="props">
        <unicon name="house-user" icon-style="monochrome" />
      </template>
    </van-tabbar-item>

    <van-tabbar-item :to="{name: 'money'}">
      <span>Финансы</span>
      <template #icon="props">
        <unicon name="chart-pie" icon-style="monochrome" />
      </template>
    </van-tabbar-item>

  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';
import {ref} from "vue";

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  }
};
</script>