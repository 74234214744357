<template>
  <div class="avatar" :style="{backgroundColor: bgColor, color: textColor}">
    <img :alt="name" :src="src" v-if="src" />
    <template v-else>
      {{ initials }}
    </template>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  computed: {
    textColor() {
      const luminance = {r: 0.2126, g: 0.7152, b:0.0722};
      const r = parseInt(this.bgColor.substring(1,3), 16);
      const g = parseInt(this.bgColor.substring(3,5), 16);
      const b = parseInt(this.bgColor.substring(5), 16);
      const rgb = [r / 255, g / 255, b / 255];
      for (let i = rgb.length; i--; )
        rgb[i] =
            rgb[i] <= 0.03928 ?
                rgb[i] / 12.92 :
                Math.pow(((rgb[i] + 0.055) / 1.055), 2.4);
      if(((luminance.r * rgb[0]) + (luminance.g * rgb[1]) + (luminance.b * rgb[2])) > 0.22) {
        return '#000000'
      }
      return '#ffffff'
    },
    bgColor() {
      let hash = 0;
      for (let i = 0; i < this.name.length; i++) {
        hash = this.name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (let j = 0; j < 3; j++) {
        let value = (hash >> (j * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    },
    initials() {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

      let initials = [...this.name.matchAll(rgx)] || [];

      return (
          (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: null
    },
  }
}
</script>

<style lang="scss">
  .avatar {
    overflow: hidden;
    img {
      max-width: 100%;
      height: auto;
    }
  }
</style>