import axios from 'axios';
import auth from '@/services/auth'
import config from '@/setup/Config'
import useEmitter from "./useEmitter";
import { showNotify } from 'vant';

export default function useApi() {
    const emitter = useEmitter()

    const getHeaders = () => {
        const headers = {'Accept': 'application/json'}

        if (auth().isLoggedIn()) {
            headers['Authorization'] = auth().getToken()
        }
        return headers
    }

    const showToast = (message, type = null, duration = 3000) => {
        showNotify({
            type: type,
            duration: duration,
            message: message
        })
    }

    const api = axios.create({
        baseURL: config().apiUrl,
        timeout: 10000,
        headers: getHeaders()
    })

    api.interceptors.request.use((config) => {
        if(auth().isLoggedIn()) {
            config.headers['Authorization'] = auth().getToken();
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });



    api.interceptors.response.use((response) => {
        if (response.data.refresh) {
            emitter.emit('refresh');
        }
        if(response?.data?.message) {
            showToast(response.data.message, response?.data?.success ? 'success' : 'danger')
        }
        return response.data;
    }, (error) => {
        if(error.response?.status === 401) {
            auth().logout()
        }
        if (error.response?.status !== 401 && error.constructor.name !== 'Cancel') {
            showToast(error?.response?.data?.message ? error.response.data.message : 'Произошла непредвиденная ошибка. Мы уже в курсе и работаем над её устранением', 'danger')
        }
        return Promise.reject(error);
    });

    return api
}




