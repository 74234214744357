export default class EventServiceOption {
    id
    title
    value

    constructor(option) {
        this.id = option.id
        this.title = option.title
        this.value = null
    }

    static make(option) {
        return new EventServiceOption(option)
    }



    // hasVariants() {
    //     return !!this.model?.variants?.length
    // }
    //
    // get getVariantModelForField() {
    //     if (this.variant && this.variant.length) {
    //         const variant = this.model.variants.find(v => v.id === this.variant[0])
    //         if (variant) {
    //             return variant.title
    //         }
    //     }
    //     return null
    // }
    //
    // confirmVariant(val) {
    //     if (val) {
    //         this.variant = [val]
    //     } else {
    //         this.variant = []
    //     }
    // }
    //
    // set getVariantModelForField(val) {
    // }
    //
    // getVariantsForPicker() {
    //     if (this.hasVariants()) {
    //         return [
    //             ...[{text: 'Не указывать', value: null}],
    //             ...this.model.variants.map(variant => ({text: variant.title, value: variant.id}))
    //         ]
    //     }
    //     return []
    //
    //
    //     // option.model?.variants.map(v => {
    //     //
    //     // })
    // }

}