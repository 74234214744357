<template>
  <div class="event-services">
    <template v-if="editable">
      <div v-if="services?.length">
          <div class="block-title">Услуги</div>

          <van-cell-group inset class="mx-0 mb-1">
            <EventServiceItem v-for="service in services"
                              :key="service.id"
                              :service="service"
                              @remove="removeService"
                              @edit="editService"
            />
            <van-cell v-if="services?.length > 1">
              <template #title>
                <strong class="event-service-title">
                  <small>Итого</small>

                  <span class="params">
                    <span>
                      <IconButton icon="money-bill" :size="16"/>
                      <NumberSpinner
                          :value="totalServicesPrice"
                          :duration="300"
                          suffix=" ₽"
                          autoinit/>
                    </span>
                              <span>
                      <IconButton icon="clock" :size="16"/>
                      {{ humanMinutes(totalServicesTime) }}
                    </span>
                  </span>
                </strong>


              </template>

            </van-cell>
          </van-cell-group>
      </div>
      <ServicePicker @save="insertService" ref="servicePickerRef" :services="services" />
    </template>
    <template v-else>
<!--      TODO -->
    </template>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import ServicePicker from "@/components/services/ServicePicker/ServicePicker";
import NumberSpinner from "@/components/helpers/NumberSpinner";
import EventServiceItem from "@/components/events/EventServiceItem";
import useDateHelper from "@/use/useDateHelper";

export default {
  setup(props, {emit}) {
    const {humanMinutes} = useDateHelper()
    const servicePickerRef = ref(null)
    const editService = (service) => {
      servicePickerRef.value.edit(service)
    }
    const removeService = (id) => {
      emit('update:services', props.services.filter(s => s.id !== id))
    }
    const insertService = (eventService) => {
      const existingIndex = props.services?.findIndex(s => s.id === eventService.id)
      if(existingIndex !== null && existingIndex !== -1) {
          emit('update:services', props.services.map(s => s.id === eventService.id ? eventService : s))
      } else {
        emit('update:services', [
          ...props.services,
          ...[eventService]
        ])
      }
    }

    const totalServicesPrice = computed(() => {
      let acc = 0
      props.services.forEach(s => {
        acc += s.price ? s.price : 0
        s.options?.forEach(o => {
          if(o.value?.price) acc += o.value.price
        })
      })
      return acc
    })
    const totalServicesTime = computed(() => {
      let acc = 0
      props.services.forEach(s => {
        acc += s.time ? s.time : 0
        s.options?.forEach(o => {
          if(o.value?.time) acc += o.value.time
        })
      })
      return acc
    })

    const getTotalTime = () => totalServicesTime.value

    return {
      editService,
      removeService,
      servicePickerRef,
      insertService,
      totalServicesPrice,
      totalServicesTime,
      humanMinutes,
      getTotalTime
    }
  },
  name: "EventServices",
  components: {
    ServicePicker,
    EventServiceItem,
    NumberSpinner
  },
  props: {
    services: {
      type: Array,
      default: []
    },
    editable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>