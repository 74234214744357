<template>
  <van-config-provider class="d-flex justify-content-center">
    <div class="d-flex align-items-center justify-content-center h-100">
      <van-loading />
    </div>
  </van-config-provider>
</template>

<script>
import {ConfigProvider} from "vant";

export default {
name: "LayoutLoading",
  components: {
    [ConfigProvider.name]: ConfigProvider,
  },
}
</script>

<style scoped>

</style>