<template>
  <div class="p-3">

    <div class="text-center my-4">
      <img class="logo" src="../../assets/logo.svg" alt="logo"/>
    </div>

    <van-form class="mb-3" ref="form" @submit="submit">

      <van-cell-group inset class="mx-0 mb-4">
        <van-field
            autocomplete="email"
            v-model="model.email"
            inputmode="email"
            name="email"
            type="email"
            placeholder="E-mail"
            :rules="[{ required: true, message: 'Обязательно для заполнения' }]"
        >
          <template v-slot:left-icon>
            <unicon name="envelope"/>
          </template>
        </van-field>

        <van-field
            v-model="model.password"
            autocomplete="current-password"
            placeholder="Пароль"
            inputmode="text"
            name="password"
            type="password"
            :rules="[{ required: true, message: 'Обязательно для заполнения' }]"
        >
          <template v-slot:left-icon>
            <unicon name="key-skeleton-alt"/>
          </template>
        </van-field>

      </van-cell-group>

      <van-button :loading="loading.state" round block type="primary" native-type="submit">
        Войти
      </van-button>
    </van-form>

    <div class="text-center">
      <router-link :to="{ name: 'forgotPassword', query: model.email ? { email: model.email } : {} }">Восстановить
        пароль
      </router-link>
    </div>
  </div>
</template>


<script>
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import useApi from "@/use/useApi";
import useLoading from "@/use/useLoading";
import useAuth from "@/use/useAuth";

export default {
  setup(_) {
    const api = useApi()
    const auth = useAuth()
    const loading = useLoading()
    const model = ref({
      email: '',
      password: '',
    })
    const route = useRoute()
    const router = useRouter()

    const submit = () => {
      loading.start()
      api.post('auth/login', model.value)
          .then((response) => {
            if (response.data?.token_type && response.data?.token) {
              login(response.data.token_type, response.data.token)
            }
          }).finally(loading.stop)
    }
    const login = (type, token) => {
      auth.login(type, token)
      setTimeout(() => {
        router.push({name: 'clients'})
      }, 500)
    }

    onMounted(() => {
      if (route.query.email) {
        model.value.email = route.query.email
      }
    })
    return {
      model,
      submit,
      loading
    }
  },
  name: "Login",
}
</script>