import { Model } from 'pinia-orm'
import {useRepo} from "pinia-orm";
import useApi from "@/use/useApi";
import pinia from "../store";

export default class BaseModel extends Model {

    static name = 'Материал'
    static pluralNames = ['материал', 'материала', 'материалов']

    $name() { return this.constructor.name }
    $pluralNames() { return this.constructor.pluralNames }
    
    static api(app) {
        const api = useApi()
        
        return {
            getAll: (params = null) => {
                return api.get(this.entity, {params: params}).then(r => useRepo(this, pinia).fresh(r.data))
            },
            getOne: (id) => {
                return api.get(this.entity+'/'+id).then(r => useRepo(this, pinia).save(r.data))
            },
            store: (model) => {
                return api.post(this.entity, model).then(r => useRepo(this, pinia).save(r.data))
            },
            update: (model) => {
                return api.patch(this.entity+'/'+model.id, model).then(r => useRepo(this, pinia).save(r.data))
            },
            destroy: (model) => {
                useRepo(this, pinia).destroy(model.id)
                return api.delete(this.entity+'/'+model.id, model)
            },
            search: (searchValue) => {
                return api.get(this.entity+'/search', {params: {q:searchValue}}).then(r => useRepo(this, pinia).save(r.data))
            },
        }
    }
}

