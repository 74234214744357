<template>
  <van-popup
      v-model:show="isVisible"
      @opened="popupOpened"
      position="top"
      closeable>
    <EventForm @created="isVisible = false" v-model:model="model" :editMode="editMode"
               ref="formRef"/>
  </van-popup>
</template>

<script>
import Event from "@/models/Event";
import EventForm from "@/components/events/EventForm";
import {nextTick, onMounted, ref} from "vue";
import useEmitter from "@/use/useEmitter";

export default {
  name: "EventFormPopup",
  setup() {
    const emitter = useEmitter()
    const formRef = ref(null)
    const isVisible = ref(false)
    const editMode = ref(false)
    const model = ref(new Event())

    const showCreate = async () => {
      showPopup()
      await nextTick()
      editMode.value = false
    }

    const popupOpened = () => {
      formRef.value?.setModel(new Event())
    }

    const showPopup = () => isVisible.value = true

    onMounted(() => {
      emitter.on('event-create', showPopup)
    })

    return {
      model,
      isVisible,
      editMode,
      showCreate,
      formRef,
      popupOpened
    }
  },
  components: {
    EventForm
  }
}
</script>