<template>
  <img @click="toggleSidebar" style="width: 64px; height: auto;" class="logo" src="@/assets/logo.svg" alt="logo"/>
</template>

<script>
export default {
  name: "HeaderLogo",
  methods: {
    toggleSidebar() {
      this.emitter.emit('toggle-sidebar');
    }
  }
}
</script>

<style scoped>

</style>