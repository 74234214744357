<template>
  <span>
    {{ humanMinutes(totalTime) }}
  </span>
</template>

<script>
import EventService from "@/utils/EventService/EventService";
import {computed} from "vue";
import useDateHelper from "@/use/useDateHelper";
export default {
  setup(props) {
    const {humanMinutes} = useDateHelper()
    const totalTime = computed(() => {
      let acc = props.model.time ? props.model.time : 0
      props.model.options?.forEach(o => {
        if(o.value?.time) acc += o.value.time
      })
      return acc
    })

    return {
      totalTime,
      humanMinutes
    }
  },

  name: "ServicePickerTime",
  props: {
    model: {
      type: EventService,
      required: true
    }
  }
}
</script>

<style scoped>

</style>