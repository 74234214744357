import { createRouter, createWebHistory } from 'vue-router'
import Login from "@/pages/auth/LoginPage"
import NotFoundPage from "@/pages/NotFoundPage";
import TabbedPageWrapper from "@/components/TabbedPageWrapper";
import auth from "@/router/middleware/auth";


const routes = [

    {
        path: '/',
        component: TabbedPageWrapper,
        children: [
            {
                path: '',
                redirect: '/events',
            },
            {
                path: 'events',
                name: 'events',
                meta:{
                    title: 'События',
                },
                component: () => import('../pages/events/EventsIndexPage.vue'),
            },
            {
                path: 'clients',
                name: 'clients',
                meta:{
                    title: 'Клиенты',
                },
                component: () => import('../pages/clients/ClientsIndexPage.vue'),
            },
            {
                path: 'clients/:id',
                name: 'clients.view',
                meta:{
                    title: 'Информация о клиенте',
                },
                component: () => import('../pages/clients/ClientsViewPage.vue'),
            },
            {
                path: 'clients/:id/edit',
                name: 'clients.edit',
                meta:{
                    title: 'Редактирование клиента',
                },
                component: () => import('../pages/clients/ClientsEditPage.vue'),
            },
            {
                path: 'settings/services',
                name: 'services',
                meta:{
                    title: 'Услуги',
                },
                component: () => import('../pages/settings/services/ServicesIndexPage.vue'),
            },
            {
                path: 'settings/services/add',
                name: 'services.add',
                meta:{
                    title: 'Новая услуга',
                },
                component: () => import('../pages/settings/services/ServicesAddPage.vue'),
            },
            {
                path: 'settings/services/:id/edit',
                name: 'services.edit',
                meta:{
                    title: 'Редактирование услуги',
                },
                component: () => import('../pages/settings/services/ServicesEditPage.vue'),
            },
            {
                path: 'money',
                name: 'money',
                meta:{
                    title: 'Финансы',
                },
                component: () => import('../pages/money/MoneyIndexPage.vue'),
            },
            {
                path: 'settings',
                name: 'settings',
                meta:{
                    title: 'Настройки',
                },
                component: () => import('../pages/settings/SettingsIndexPage.vue'),
            },
        ],
    },

    {
        path: '/auth/login',
        name: 'login',
        component: Login,
        meta:{
            title: 'Логин',
            layout:'LayoutAuth'
        }
    },
    {
        path: '/auth/forgot_password',
        name: 'forgotPassword',
        component: () => import('@/pages/auth/ForgotPassPage.vue'),
        meta:{
            title: 'Восстановление пароля',
            layout:'LayoutAuth'
        }
    },
    {
        path: "/:catchAll(.*)",
        name: 'NotFound',
        component: NotFoundPage,
        meta:{
            title: 'Страница не найдена',
            layout:'LayoutAuth'
        }
    }
];

const Router = createRouter({
    history: createWebHistory(),
    routes
})

Router.beforeEach((to, from, next) => {

    document.title = to.meta.title ? to.meta.title + ' — AM studio' : 'AM studio';

    const context = {to,from,next}
    return auth({...context})
})

export default Router;