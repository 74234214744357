<template>
  <van-notify @click="refreshApp" class="justify-content-start text-left" v-model:show="updateExists" type="primary">
    <unicon class="mr-3" name="refresh" />
    <span>Найдена новая версия приложения. <br>Нажмите для обновления</span>
  </van-notify>

  <PWAPrompt/>

  <component :is="this.layout">
    <slot />
  </component>


</template>

<script>

import LayoutLoading from "@/layouts/LayoutLoading";
import LayoutDefault from "@/layouts/LayoutDefault";
import {markRaw} from "@vue/reactivity";
import updatePwa from "@/mixins/updatePwa";
import PWAPrompt from "@/components/helpers/PWAPrompt";
import { Notify } from 'vant'

export default {
  name: "App",
  mixins: [updatePwa],
  components: {
    PWAPrompt,
    [Notify.name]: Notify
  },
  data() {
    return {
      layout: markRaw(LayoutLoading)
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.setLayout(route.meta.layout)
      }
    }
  },
  methods: {
    async setLayout(layout) {
      if(!layout) layout = 'LayoutDefault'
      try {
        const component = await import(`@/layouts/${layout}.vue`)
        this.layout = markRaw(component?.default || LayoutDefault)
      } catch (e) {
        this.layout = markRaw(LayoutDefault)
      }
    }
  }
}
</script>
