<!-- PWAPrompt.vue -->
<template>
  <van-popup v-model:show="shown" class="px-2 pt-6 pb-4">
    <div class="text-center">
      <img style="width: 80px; height: auto;" src="@/assets/favicon.svg" alt="">
      <div>
        <p class="mb-4">
          Установить приложение на домашний экран?
        </p>
        <p>
          <van-button @click="installPWA" block type="primary" round>Установить</van-button>
        </p>
        <van-button @click="dismissPrompt" type="text" round>Не устанавливать</van-button>
      </div>

    </div>
  </van-popup>
</template>

<script>
export default {
  data: () => ({
    shown: false,
    installEvent: null
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  }
}
</script>