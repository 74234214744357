<template>
  <van-form ref="form" @submit="onSubmit">
    <div class="px-2">
      <h4>
        {{ editMode ? 'Редактирование событя' : 'Новое событие'}}
      </h4>
    </div>


    <van-tabbar :fixed="false" v-model="model.type">
      <van-tabbar-item name="service">
        <template #icon="props">
          <unicon name="calendar-alt"/>
        </template>
        Услуга
      </van-tabbar-item>
      <van-tabbar-item name="task">
        <template #icon="props">
          <unicon name="file-check-alt"/>
        </template>
        Задача
      </van-tabbar-item>
      <van-tabbar-item name="note">
        <template #icon="props">
          <unicon name="comment-info-alt"/>
        </template>
        Заметка
      </van-tabbar-item>
    </van-tabbar>

    <div class="p-2 bg-light">

      <template v-if="model.type==='service'">

        <EventServices
            ref="eventServices"
            class="mb-1"
            editable
            v-model:services="model.services"/>



        <DateTimeInput
            class="mb-1"
            buttonText="Выбрать дату начала"
            titleText="Дата и время начала"
            v-model:value="model.date_start"
            @update:value="dateStartUpdated"
        />


        <DateTimeInput
            v-show="model.date_start"
            class="mb-1"
            buttonText="Дата завершения"
            titleText="Дата и время завершения"
            v-model:value="model.date_end"
            :minDate="model.date_start"
            @update:value="$refs.form.validate()"
        />


        <div class="mb-1">
          <ClientSelector v-model:value="model.client_id"/>
        </div>


        <van-cell-group inset class="mx-0 mb-2">
          <van-field
              v-model="model.comment"
              placeholder="Заметка"
              inputmode="text"
              name="comment"
              type="textarea"
              rows="2"
              autosize
          />
        </van-cell-group>

      </template>

      <template v-if="model.type==='task'">
        <van-cell-group inset class="mx-0 mb-2">
          <van-field
              v-model="model.comment"
              placeholder="Задача"
              inputmode="text"
              name="comment"
              type="textarea"
              rows="2"
              autosize
          />
        </van-cell-group>

        <DateTimeInput
            class="mb-1"
            buttonText="Выбрать дату начала"
            titleText="Дата и время начала"
            v-model:value="model.date_start"
            @update:value="dateStartUpdated"
        />


        <DateTimeInput
            v-show="model.date_start"
            class="mb-1"
            buttonText="Дата завершения"
            titleText="Дата и время завершения"
            v-model:value="model.date_end"
            :minDate="model.date_start"
            @update:value="$refs.form.validate()"
        />
        <div class="mb-1">
          <ClientSelector v-model:value="model.client_id"/>
        </div>

      </template>

      <template v-if="model.type==='note'">
        <van-cell-group inset class="mx-0 mb-2">
          <van-field
              v-model="model.comment"
              placeholder="Заметка"
              inputmode="text"
              name="comment"
              type="textarea"
              rows="2"
              autosize
          />
        </van-cell-group>
        <div class="mb-1">
          <ClientSelector v-model:value="model.client_id"/>
        </div>

      </template>


      <van-button :loading="loading" round block type="primary" native-type="submit">
        Сохранить
      </van-button>

    </div>


  </van-form>
</template>

<script>
import EventServices from "@/components/events/EventServices";
import ClientSelector from "@/components/formElements/ClientSelector";
import { Tabbar, TabbarItem } from 'vant';
import Event from "@/models/Event";
import DateTimeInput from "@/components/formElements/DateTimeInput";
import LoadingMixin from "@/mixins/LoadingMixin";
import useDayjs from "@/use/useDayjs";
export default {
  name: "EventForm",
  mixins: [LoadingMixin],
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    ClientSelector,
    EventServices,
    DateTimeInput
  },
  data() {
    return {
      model: new Event()
    }
  },
  methods: {
    dateStartUpdated(e) {
      const dayjs = useDayjs
      if(this.$refs.eventServices?.getTotalTime()) {
        this.model.date_end = dayjs(e).add(this.$refs.eventServices?.getTotalTime(), 'minutes').toDate()
      }
      this.$refs.form.validate()
    },
    reset() {
      this.model = new Event()
    },
    setModel(model) {
      this.model = Object.assign({}, model)
    },
    onSubmit() {
      this.startLoading()
      Event.api(this).store(this.model)
          .then(() => {
            this.$emit('created')
          })
          .finally(() => this.stopLoading())

    }
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>