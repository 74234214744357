import {
    Button,
    NavBar,
    Form,
    Field,
    Locale,
    CellGroup,
    Search,
    List,
    Picker,
    Sticky,
    Cell,
    Empty,
    Popup,
    Skeleton,
    Space,
    Loading,
    ActionSheet,
    DropdownMenu,
    DropdownItem
} from 'vant';

import ruRU from 'vant/es/locale/lang/ru-RU';

Locale.use('ru-RU', ruRU);

export default (app) => {
    app.use(Button)
        .use(NavBar)
        .use(Form)
        .use(Field)
        .use(CellGroup)
        .use(Search)
        .use(List)
        .use(Picker)
        .use(Cell)
        .use(Sticky)
        .use(Empty)
        .use(Popup)
        .use(Space)
        .use(Skeleton)
        .use(Loading)
        .use(ActionSheet)
        .use(DropdownMenu)
        .use(DropdownItem)
}