import helpers from "@/utils/helpers";
import * as dayjs from 'dayjs'
require('dayjs/locale/ru')
dayjs.extend(require('dayjs/plugin/relativeTime'))
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))
dayjs.locale('ru')

export default (app) => {
    if(app) {
        app.config.globalProperties.$helpers = helpers
        app.config.globalProperties.$dayjs = dayjs
    }
}
