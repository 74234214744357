import {ref, reactive} from "vue";

export default function useLoading(defaultState = false) {
    const touched = ref(false)
    const setTouched = () => touched.value = true
    const state = ref(defaultState)
    const start = () => {
        setTouched()
        state.value = true
    }
    const stop = () => {
        setTouched()
        state.value = false
    }
    const toggle = () => {
        setTouched()
        state.value = !state.value
    }

    return reactive({
        state,
        touched,
        start,
        stop,
        toggle
    })
}
