import { defineStore  } from 'pinia'

const token_key = 'token'

const userStore = defineStore('user', {
    state: () => {
        return {
            user: null,
        }
    },
})

const login = (type, token) => {
    localStorage.setItem(token_key, type+' '+token)
}

const logout = () => {
    if(isLoggedIn()) {
        localStorage.removeItem(token_key)
        userStore().user = null
        window.location.reload()
    }
}

const isLoggedIn = () => {
    return !!localStorage.getItem(token_key)
}

const getToken = () => {
    return localStorage.getItem(token_key)
}

const user = () => {
    return userStore().user
}

const id = () => {
    return userStore().user?.id
}

const loadUser = (api) => {
    if (isLoggedIn()) {
        api.defaults.headers.common['Authorization'] = getToken();
        return api.get('auth/user').then((r) => userStore().user = r.data)
    }
}

export default (app) => {
    const Auth = { login, logout, isLoggedIn, getToken, loadUser, userStore, user, id }
    if(app) app.auth = Auth
    return Auth
}

