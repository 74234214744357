<template>
  <van-cell class="align-items-start" @click="emit('edit', service)">
    <template #title>
      <div class="event-service-title">
         <header>{{ service.title }}</header>

        <div class="params">
          <span>
            <IconButton icon="money-bill" :size="16"/>
            <ServicePickerPrice :model="service"/>
          </span>
          <span>
            <IconButton icon="clock" :size="16"/>
            <ServicePickerTime :model="service"/>
          </span>
        </div>
      </div>
    </template>
    <template #label>
                <span class="small ghost">
                <template v-for="(option, index) in service.options.filter(o => !!o.value)">
                    <template v-if="index > 0">
                      ,&nbsp
                    </template>
                    {{option?.title }}:
                    <template v-if="option.value?.title">{{ option.value.title }}</template>
                    <template v-else>{{ option.value }}</template>

                </template>
                </span>
    </template>
    <template #right-icon>
      <IconButton class="ml-2" @click.stop="emit('remove', service.id)" iconClass="color-red" icon="times"/>
    </template>
  </van-cell>
</template>

<script>
import EventService from "@/utils/EventService/EventService";
import ServicePickerPrice from "@/components/services/ServicePicker/ServicePickerPrice";
import ServicePickerTime from "@/components/services/ServicePicker/ServicePickerTime";

export default {
  setup(props, {emit}) {
    return {
      emit
    }
  },
  name: "EventServiceItem",
  components: {
    ServicePickerPrice,
    ServicePickerTime,
  },
  props: {
    service: {
      type: EventService,
      required: true
    }
  }
}
</script>

<style lang="scss">
.event-service-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .params {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 12px;
    &>span {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

</style>