<template>
  <template v-if="selectedModel">
    <div class="block-title">
      Клиент
    </div>
    <div class="block d-flex gap-1">
      <ClientCardSimple inset class="flex-grow-1" :model="selectedModel"/>
      <IconButton @click="selectClient(null)" icon="times" :size="18"/>
    </div>
  </template>


  <van-button v-else @click="showPopup" round size="small">
    <template #icon>
      <unicon width="18" height="18" class="color-primary mr-1" name="plus-circle"/>
    </template>
    Выбрать клиента
  </van-button>

  <van-popup v-model:show="isVisible" position="top" closeable>
    <h4 class="px-2">Выбор клиента</h4>
    <van-search
        @clear="clear"
        clearable
        clear-trigger="always"
        class="flex-grow-1"
        v-debounce:500ms="onSearch"
        v-model="searchValue"
        placeholder="Поиск по имени и телефону">
      <template #right-icon>
        <van-loading v-if="searchLoading" color="#1989fa" size="16"/>
      </template>
    </van-search>

    <ClientCardSimple @click="selectClient(model.id)" v-for="model in models" :key="model.id" :model="model"/>

    <div class="p-2">

    </div>
  </van-popup>
</template>

<script>
import {vue3Debounce} from 'vue-debounce'
import LoadingMixin from "@/mixins/LoadingMixin";
import Client from "@/models/Client";
import ClientCardSimple from "@/components/clients/ClientCardSimple";
import {useRepo} from "pinia-orm";

export default {
  name: "ClientSelector",
  directives: {
    debounce: vue3Debounce({})
  },
  mixins: [LoadingMixin],
  components: {
    ClientCardSimple
  },
  data() {
    return {
      isVisible: false,
      editMode: false,
      searchLoading: false,
      searchValue: '',
      foundModels: []
    }
  },
  computed: {
    models() {
      if (this.foundModels.length) {
        return this.foundModels
      } else {
        return this.modelsFromStore
      }
    },
    modelsFromStore() {
      return useRepo(Client).all()
    },
    selectedModel() {
      if (this.value) {
        const m = useRepo(Client).find(this.value)
        if (m) return m
        else this.loadData()
      }
      return null
    }
  },
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  methods: {
    loadData() {
      this.startLoading()
      Client.api(this).getOne(this.value).finally(() => this.stopLoading())
    },
    selectClient(id) {
      this.$emit('update:value', id)
      this.closePopup()
    },
    clear() {
      this.searchValue = ''
      this.stopLoading()
    },
    onSearch() {
      if (this.searchValue) {
        this.searchLoading = true
        Client.api(this).search(this.searchValue).then(response => {
          this.foundModels = response.length ? response : []
        }).finally(() => {
          this.searchLoading = false
        })
      }
    },
    closePopup() {
      this.isVisible = false
    },
    showPopup() {
      this.isVisible = true
    }
  }
}
</script>

<style scoped>

</style>