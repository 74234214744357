import authService from '@/services/auth'

function routeNeedsAuth(routerObj) {
    const routeNamesWithoutAuth = ['login', 'forgotPassword']
    return !routeNamesWithoutAuth.includes(routerObj.name);
}

export default function auth({to, next}) {
    if(routeNeedsAuth(to)) {
        authService().isLoggedIn() ? next() : next({name: 'login'})
    } else {
        authService().isLoggedIn() ? next({name: 'clients'}) : next()
    }
}