<template>
  <div>
    <van-field
        :rules="[
          {validator: isDateValidator, message: 'Ошибка. Попробуйте выбрать дату заново'},
          {validator: minDateValidator, message: 'Дата завершения не может быть раньше даты начала'}
          ]"
        class="date-field">
      <template #input>
        <template v-if="value">
          <div class="block-title">
            {{ titleText }}
          </div>
          <div class="block d-flex gap-1">
            <div class="date-button flex-grow-1" @click="showDatePicker">
              <unicon name="calendar-alt" width="16" height="16"/>
              <div>
                <span class="primary">{{ $dayjs(value).format('DD MMMM YYYY')}}</span>
                <span class="secondary">
            {{ $dayjs(value).format('dddd') }}
            ({{ $dayjs(value).fromNow() }})
          </span>
              </div>
            </div>

            <div v-if="hasTime" class="date-button" @click="showTimePicker">
              <unicon name="calendar-alt" width="16" height="16"/>
              <span class="primary">
                  {{ $dayjs(value).format('HH:mm')}}
              </span>
            </div>

            <IconButton @click="$emit('update:value', null)" icon="times" :size="18"/>
          </div>
        </template>

        <template v-else>
            <van-button @click="showDatePicker" round size="small">
              <template #icon>
                <unicon width="18" height="18" class="color-primary mr-1" name="plus-circle"/>
              </template>
              {{ buttonText }}
            </van-button>

            <template v-if="minDate">
              <van-button @click="setDate($dayjs(minDate).add(30, 'minutes'))" round size="mini" class="ml-1">+30 мин</van-button>
              <van-button @click="setDate($dayjs(minDate).add(60, 'minutes'))" round size="mini">+1 ч</van-button>
              <van-button @click="setDate($dayjs(minDate).add(90, 'minutes'))" round size="mini">+1.5 ч</van-button>
              <van-button @click="setDate($dayjs(minDate).add(120, 'minutes'))" round size="mini">+2 ч</van-button>
            </template>
        </template>
      </template>
    </van-field>

    <van-calendar
        @select="setTempDate"
        @confirm="saveDateValue"
        :min-date="datepickerMinDate"
        first-day-of-week="1"
        v-model:show="datepickerIsVisible"
        :default-date="datepickerDefaultDate"
        :show-confirm="true"
        title="Выбор даты"
    >
      <template v-slot:month-title="slotProps">
        {{ $dayjs(slotProps.date).format('MMMM YYYY') }}
      </template>
      <template #subtitle>
        <div class="d-flex flex-wrap gap-1 px-1 mb-1">
          <van-button @click="setTempDate($dayjs().subtract(1, 'day'))" size="small" round>Вчера</van-button>
          <van-button @click="setTempDate($dayjs())" size="small" round>Сегодня</van-button>
          <van-button @click="setTempDate($dayjs().add(1, 'day'))" size="small" round>Завтра</van-button>
          <van-button @click="setTempDate($dayjs().add(2, 'day'))" size="small" round>Послезавтра</van-button>
        </div>
      </template>
    </van-calendar>

    <van-popup
        position="bottom"
        v-model:show="timepickerIsVisible"
        round
        closeable
    >
      <van-time-picker
          :filter="timeOptionsFilter"
          v-model="tempTime"
          title="Choose Time"
          :columns-type="timeColumnsType"
      >
        <template #toolbar>
          <div class="timepicker-header">
            Выбор времени
          </div>
        </template>
      </van-time-picker>

      <div v-if="!checkMinDate()" class="text-center color-error small p-1">
        Время завершения не может быть раньше времени начала ({{ $dayjs(minDate).format('DD MMMM YYYY — HH:mm')}})
      </div>
      <van-button @click="saveTimeValue" class="van-calendar__confirm" type="primary" round block>Подтвердить</van-button>
    </van-popup>
  </div>
</template>

<script>
import { Calendar } from 'vant';
import { TimePicker } from 'vant';

export default {
  name: "DateTimeInput",
  components: {
    [Calendar.name]: Calendar,
    [TimePicker.name]: TimePicker
  },
  data() {
    return {
      timepickerIsVisible: false,
      datepickerIsVisible: false,
      tempDate: null,
      timeColumnsType: ['hour', 'minute'],
    }
  },
  computed: {
    tempTime: {
      get() {
        let h = this.$dayjs(this.value)
            .hour()
            .toString()
        if(h.length === 1) h = '0'+h

        let m = this.$dayjs(this.value)
            .minute()
            .toString()
        if(m.length === 1) m = '0'+m

        return [h, m]
      },
      set(value) {
        this.tempDate = this.$dayjs(this.value)
            .hour(parseInt(value[0]))
            .minute(parseInt(value[1]))
            .toDate()
      },
    },
    datepickerDefaultDate() {
      if(this.tempDate) {
        return this.tempDate
      }
      if(this.value) {
        return this.value
      }
      return this.minDate ? this.minDate : new Date
    },
    datepickerMinDate() {
      return this.minDate ? this.minDate : this.$dayjs().subtract(1, 'year').toDate()
    }
  },
  methods: {
    isDateValidator() {
      if(this.value) {
        return this.value.constructor.name === 'Date'
      }
      return true
    },
    minDateValidator() {
      if(this.value && this.minDate) {
        return this.$dayjs(this.value).isSameOrAfter(this.$dayjs(this.minDate))
      }
      return true
    },
    timeOptionsFilter(type, options) {
      if (type === 'minute') {
        return options.filter((option) => Number(option.value) % 5 === 0)
      }
      return options;
    },
    showDatePicker() {
      this.datepickerIsVisible = true
    },
    hideDatePicker() {
      this.datepickerIsVisible = false
    },
    showTimePicker() {
      this.timepickerIsVisible = true
    },
    hideTimePicker() {
      this.timepickerIsVisible = false
    },
    checkTempDate() {
      if(!this.tempDate) {
        this.tempDate = this.datepickerDefaultDate
      }
    },
    checkMinDate(onlyDate = false) {
      this.checkTempDate()
      if(this.minDate) {
        if(onlyDate) {
          return this.$dayjs(this.tempDate).startOf('day')
              .isSameOrAfter(this.$dayjs(this.minDate).startOf('day'))
        }
        return this.$dayjs(this.tempDate)
            .isSameOrAfter(this.$dayjs(this.minDate))
      }
      return true
    },
    setDate(date) {
      this.setTempDate(date)
      this.saveTimeValue()
    },
    setTempDate(date) {
      if(date.constructor.name === 'Date') {
        date = this.$dayjs(date)
      }
      if(this.value) {
        const h = this.$dayjs(this.value).hour()
        const m = this.$dayjs(this.value).minute()
        this.tempDate = date.hour(h).minute(m).toDate()
      } else {
        this.tempDate = date.toDate()
      }
    },
    saveDateValue() {
      this.checkTempDate()
      if(this.checkMinDate(true)) {
        this.$emit('update:value', this.datepickerDefaultDate)
        this.hideDatePicker()
        this.showTimePicker()
      }
    },
    saveTimeValue() {
      if(this.checkMinDate()) {
        this.$emit('update:value', this.tempDate)
        this.hideTimePicker()
      }
    }
  },
  props: {
    value: {
      type: Date,
      default: null
    },
    hasTime: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: Date,
      default: null
    },
    buttonText: {
      type: String,
      default: 'Выбрать дату'
    },
    titleText: {
      type: String,
      default: 'Дата и время'
    },

  },
}
</script>

<style scoped>

</style>