import {ref, reactive} from "vue";

export default function useStringHelper() {
    /**
     * Склонение существительных в зависимости от количества
     * @param count
     * @param titles
     * @param showCount
     * @param cases
     * @returns {string|*}
     */
    const plural = (count, titles, showCount = true, cases = [2, 0, 1, 1, 1, 2]) => {
        if (showCount) {
            return count + ' ' + titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]]
        } else {
            return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]]
        }
    }

    /**
     * Обрезает строку до нужной длины
     */
    const strLimit = (str, limit = 20, showEllipse = true) => {
        if (str?.length && str.length > limit) {
            let limited = str.substring(0, limit)
            if (showEllipse) limited += "…"
            return limited
        }
        return str
    }

    return reactive({
        plural,
        strLimit
    })
}
